<template>
  <v-container>
    <v-row>
      <v-row class="d-flex align-center mx-2">
        <v-col cols="auto">
          <v-icon
            large
            class="mb-2"
          >
            mdi-billboard
          </v-icon>
          <span class="text-h2"> Screens</span>
        </v-col>
        <v-spacer />
        <!-- Media Formats
        <v-col
          cols="auto"
          class="text-right"
        >
          <v-speed-dial
            v-if="$store.state.Permissions.MediaTypeRead"
            direction="left"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-btn
                small
                color="primary"
                fab
                
              >
                <v-icon medium>
                  mdi-cog
                </v-icon>
              </v-btn>
            </template>
            <v-btn
              rounded
              color="primary"
              @click="mediaFormatsDialog = true"
            >
              <v-icon left>
                pat-format
              </v-icon>
              Media Formats
            </v-btn>
          </v-speed-dial>
        </v-col>
        -->
      </v-row>
      <v-col
        cols="12"
        class="my-0 py-0"
      >
        <v-card class="pa-4">
          <v-row class="mt-4 px-2 d-flex align-center">
            <v-col cols="6">
              <v-text-field
                v-model="searchFormat"
                label="Search Screens"
                outlined
                @keyup.enter.native="updatedSearchFormats"
                @keyup.native="updatedSearchFormats"
                append-icon="mdi-magnify"
                clearable
                @click:append="updatedSearchFormats"
                @click:clear="clearSearch"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              class="text-right mb-6"
            > 
              <v-tooltip top>
                <template v-slot:activator="{on,attrs}">
                  <v-btn
                    v-if="$store.state.Permissions.ScreenCreate"
                    v-on="on"
                    v-bind="attrs"
                    color="primary"
                    fab
                    small
                    @click="$router.push({ name: 'EditFormat'})"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Screen</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row >
          <v-data-table
            :headers="headers"
            :items="returnSortedScreens"
            :expanded.sync="expanded"
            item-key="id"
            show-expand
            style="width:100%"
          >
            <template v-slot:expanded-item="{ item }">   
              <td>
              </td>
              <td>
              </td>
              <td>
                <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.deliveryType.name }}</p>
              </td>
              <td>
                <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.category }}</p>
              </td>
              <td>
                <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.width }}</p>
              </td>
              <td>
                <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.height }}</p>
              </td>
              <td>
                <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.aspectRatio }}</p>
              </td>
              <td>
                <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.orientation }}</p>
              </td>
              <td>
                <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.rotation }}</p>
              </td>
              <td>
                <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.dynamicScreen.isResponsive}}</p>
              </td>
              <!--
              <td>
                <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.brandingBadgeScreen == null ? 0 : screen.screen.brandingBadgeScreen.length }}</p>
              </td>
              <td>
                <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.numberOfScreens }}</p>
              </td>
              -->
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="$store.state.Permissions.ScreenUpdate"
                icon
                color="primary"
                class="mr-2"
                @click="editFormat(item)"
              >
                <v-icon color="primary">
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="mediaFormatsDialog"
      width="800"
    >
      <v-card class="pa-4" style="padding: 0 !important;">
        <v-card-title class="white--text primary" 
>
          Media Formats
        </v-card-title>
        <v-row>
          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              v-if="$store.state.Permissions.MediaTypeCreate"
              color="primary"
              fab
              @click="
                mediaTypeSettingDialog = true;
                editMode = false;
                mediaType = {};
              "
              style="margin-top:10px; margin-right: 20px"
              
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              v-if="$store.state.Permissions.MediaTypeRead"
              :items="mediaTypes"
              :headers="headersMediaTypes"
            >
              <template v-slot:item.audioSupport="{ item }">
                <v-icon v-if="item.audioSupport">
                  mdi-volume-high
                </v-icon>
                <v-icon v-else>
                  mdi-volume-off
                </v-icon>
              </template>
              <template v-slot:item.videoSupport="{ item }">
                <v-icon v-if="item.videoSupport">
                  mdi-video-check
                </v-icon>
                <v-icon v-else>
                  mdi-video-off
                </v-icon>
              </template>
              <template
                v-if="$store.state.Permissions.MediaTypeUpdate"
                v-slot:item.actions="{ item }"
              >
                <v-btn
                  icon
                  color="primary"
                  class="mr-2"
                  @click="
                    mediaTypeSettingDialog = true;
                    editMode = true;
                    mediaType = item;
                  "
                >
                  <v-icon color="primary">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- Media Type Settings -->
        <v-dialog
          v-model="mediaTypeSettingDialog"
          width="600"
        >
          <v-card>
            <v-card-title class="white--text primary">
              {{ editMode === true ? "Edit Media Format" : "Create Media Format" }}
            </v-card-title>
            <v-row class="pa-4">
              <v-col cols="6">
                <v-select
                  v-model="mediaType.audioSupport"
                  outlined
                  label="Supports Audio?"
                  :items="supportedAudioOptions"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="mediaType.colourMode"
                  outlined
                  label="Colour Mode"
                  :items="colorModes"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="mediaType.dpi"
                  outlined
                  label="DPI"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="mediaType.fileExtension"
                  outlined
                  label="File Extension"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="mediaType.framesPerSecond"
                  outlined
                  label="Frames Per Second"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="mediaType.bitrate"
                  outlined
                  label="Bitrate"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="mediaType.videoCodec"
                  outlined
                  label="Video Codec"
                />
              </v-col>
              <v-col
                cols="6"
                class="text-left"
              >
                <v-btn
                  v-if="editMode"
                  color="red"
                  @click="deleteMediaFormatDialog = true"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                class="text-right"
              >
                <v-btn
                  color="primary"
                  @click="submitMediaFormat()"
                >
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>

        <!-- Delete Media Format -->
        <v-dialog
          v-model="deleteMediaFormatDialog"
          width="600"
        >
          <v-card class="pa-4">
            Are you sure you want to delete this media format?
            <v-card-actions>
              <v-col
                cols="12"
                class="text-right"
              >
              
                <v-btn
                  color="primary"
                  @click="deleteMediaFormat"
                >
                  Confirm
                </v-btn>
                <v-btn
                  color="red"
                  class="mr-3"
                  @click="
                    deleteMediaFormatDialog = false;
                    editMode = false;
                  "
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import MediaTypeController from '@/services/controllers/MediaType'
  import ScreenController from '@/services/controllers/Screen'

  export default {
    data: () => ({
      expanded: [],
      mediaTypes: [],
      editMode: false,
      deleteMediaFormatDialog: false,
      mediaTypeSettingDialog: false,
      colorModes: ['RGB', 'CMYK'],
      supportedAudioOptions: [true, false],
      mediaType: {},
      headersMediaTypes: [
        {
          text: 'Supports Audio?',
          align: 'start',
          sortable: true,
          value: 'audioSupport',
        },
        {
          text: 'Bitrate',
          align: 'start',
          sortable: true,
          value: 'bitrate',
        },
        {
          text: 'Colour Mode',
          align: 'start',
          sortable: true,
          value: 'colourMode',
        },
        {
          text: 'DPI',
          align: 'start',
          sortable: true,
          value: 'dpi',
        },
        {
          text: 'File Extension',
          align: 'start',
          sortable: true,
          value: 'fileExtension',
        },
        {
          text: 'Frames Per Second',
          align: 'start',
          sortable: true,
          value: 'framesPerSecond',
        },
        {
          text: 'Video Codec',
          align: 'start',
          sortable: true,
          value: 'videoCodec',
        },
        {
          text: 'Video Support',
          align: 'start',
          sortable: true,
          value: 'videoSupport',
        },
        {
          text: 'Actions',
          align: 'start',
          sortable: true,
          value: 'actions',
        },
      ],
      mediaFormatsDialog: false,
      searchFormat: '',

      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
      ],

      slides: ['First', 'Second', 'Third', 'Fourth', 'Fifth'],

      headers: [
        {
          text: 'Media Owner',
          align: 'start',
          sortable: true,
          value: 'mediaOwner.friendlyName',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Delivery Type',
          align: 'start',
          sortable: true,
          value: '',
        },
        {
          text: 'Category',
          align: 'start',
          sortable: true,
          value: 'category',
        },
        {
          text: 'Width',
          align: 'start',
          sortable: true,
          value: 'width',
        },
        {
          text: 'Height',
          align: 'start',
          sortable: true,
          value: 'height',
        },
        {
          text: 'Aspect ratio',
          align: 'start',
          sortable: true,
          value: 'aspectRatio',
        },
        {
          text: 'Orientation',
          align: 'start',
          sortable: true,
          value: 'orientation',
        },
                {
          text: 'Rotation',
          align: 'start',
          sortable: true,
          value: 'screen.rotation',
        },
        {
          text: 'Responsive',
          align: 'start',
          sortable: true,
          value: 'screen.dynamicScreen.isResponsive',
        },
        /*
        {
          text: 'Branding Badge Screen',
          align: 'start',
          sortable: true,
          value: '',
        },
        {
          text: 'Child Screens',
          align: 'start',
          sortable: true,
          value: '',
        },
        */
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
        },
        { text: '', value: 'data-table-expand' },
      ],

      formats: [],
      searchTimeout: null,
    }),

    created () {
      MediaTypeController.getMediaTypes().then((res) => {
        this.mediaTypes = res.data
      })
      this.getAllScreens()
    },

    computed: {
      returnSortedScreens() {
        let clone = [...this.formats]

        if(clone.length > 0) {
          clone.sort((a,b) =>
            a.mediaOwner.friendlyName.localeCompare(b.mediaOwner.friendlyName) ||
            a.name.localeCompare(b.name)
          )
        }

        return clone
      }
    },

    methods: {
      getAllScreens() {
        ScreenController.allScreens().then((res) => {
          this.formats = res.data
        })
      },
      deleteMediaFormat () {
        MediaTypeController.deleteItemById(this.mediaType.id)
          .then((res) => {
            this.$root.$emit(
              'snackbarSuccess',
              'Media type deleted successfully.',
            )
            this.mediaType = {}
            this.mediaTypeSettingDialog = false
            this.deleteMediaFormatDialog = false
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },
      submitMediaFormat () {
        if (this.editMode === false) {
          MediaTypeController.createMediaType({
            fileExtension: this.mediaType.fileExtension,
            colourMode: this.mediaType.colourMode,
            dpi: this.mediaType.dpi,
            videoCodec: this.mediaType.videoCodec,
            framesPerSecond: this.mediaType.framesPerSecond,
            bitrate: this.mediaType.bitrate,
            audioSupport: this.mediaType.audioSupport,
          })
            .then((res) => {
              this.$root.$emit(
                'snackbarSuccess',
                'Media type added successfully.',
              )
              this.mediaType = {}
              this.mediaTypeSettingDialog = false
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', ''+err.response.data.message)
            })
        } else {
          MediaTypeController.editMediaType({
            id: this.mediaType.id,
            fileExtension: this.mediaType.fileExtension,
            colourMode: this.mediaType.selectedColorMode,
            dpi: this.mediaType.dpi,
            videoCodec: this.mediaType.videoCodec,
            framesPerSecond: this.mediaType.framesPerSecond,
            bitrate: this.mediaType.bitrate,
            audioSupport: this.mediaType.audioSupport,
          })
            .then((res) => {
              this.$root.$emit(
                'snackbarSuccess',
                'Media type edited successfully.',
              )
              this.mediaType = {}
              this.editMode = false
              this.mediaTypeSettingDialog = false
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', ''+err.response.data.message)
              this.mediaTypeAddError = err
            })
        }
      },
      deleteScreen (id) {
        ScreenController.deleteScreenById(id)
          .then((res) => {
            this.$root.$emit('snackbarSuccess', 'Screen deleted successfully.')
            // Get formats again after delete.
            ScreenController.allScreens().then((res) => {
              this.formats = res.data
            })
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },
      clearSearch () {
        this.searchFormat = null
        ScreenController.allScreens().then((res) => {
          this.formats = res.data
        })
      },
      updatedSearchFormats() {
        if (this.searchFormat === null || this.searchFormat === undefined || this.searchFormat === '') {
          this.getAllScreens()
          return
        }
        if (this.searchFormat.length < 1) {
          return
        }
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout)
        }
        this.searchTimeout = setTimeout(() => {
          this.searchFormats()
        }, 1000)
      },
      searchFormats () {
        ScreenController.searchFormats(this.searchFormat)
          .then((res) => {
            this.formats = res.data
          })
      },
      editFormat (value) {
        this.$router.push({ name: 'EditFormat', query: { id: value.id } })
      },
      addNewFormat () {
        this.$router.push({ name: 'CreateFormat' })
      },
      goToCreatives (value) {
        this.$router.push({ name: 'Creatives', query: { bid: value.id } })
      },
      goToFormatDetails (value) {
        this.$router.push({ name: 'Format', query: { id: value } })
      },
      genRandomIndex (length) {
        return Math.ceil(Math.random() * (length - 1))
      },
    },
  }
</script>
